<template>
  <div class="tianliangle">
    <Header
      isNoBg="#0B0B0B"
      theme="dark"
      @clickRight="showTip = true"
      callBack
      @click="goBack"
    >
      <template v-slot:default>第一轮-排列座位</template>
      <template v-slot:right>
        <img class="tips" src="../../assets/images/tips.png" alt="" />
      </template>
    </Header>
    <div class="container">
      <div class="info">
        <div class="left">完成座位排列后可进入第二轮</div>
        <div class="right">
          <img src="../../assets/images/tianliangle/bei.png" alt="" />
          <span>北</span>
        </div>
      </div>
      <div class="table">
        <div
          class="top"
          :class="isSelect && !query.top.listPic ? 'active' : null"
          @click="isSelect ? stopSelect('top') : null"
        >
          <img :src="query.top.listPic" v-if="query.top.listPic" alt="" />
          <i v-else @click="tip"></i>
        </div>
        <div class="middle">
          <div
            class="left"
            :class="isSelect && !query.left.listPic ? 'active' : null"
            @click="isSelect ? stopSelect('left') : null"
          >
            <img :src="query.left.listPic" v-if="query.left.listPic" alt="" />
            <i v-else @click="tip"></i>
          </div>
          <div>
            <img
              class="banner"
              src="../../assets/images/tianliangle/logo.jpeg"
              alt=""
            />
          </div>
          <div
            class="right"
            :class="isSelect && !query.right.listPic ? 'active' : null"
            @click="isSelect ? stopSelect('right') : null"
          >
            <img :src="query.right.listPic" v-if="query.right.listPic" alt="" />
            <i v-else @click="tip"></i>
          </div>
        </div>
        <div
          class="bottom"
          :class="isSelect && !query.bottom.listPic ? 'active' : null"
          @click="isSelect ? stopSelect('bottom') : null"
        >
          <img :src="query.bottom.listPic" v-if="query.bottom.listPic" alt="" />
          <i v-else @click="tip"></i>
        </div>
      </div>
      <div class="info" style="margin: 25px 0 15px">
        <div class="left">请将下方角色放置到对应位置</div>
      </div>
      <div class="list">
        <div
          class="listItem"
          v-for="item in gameList"
          :key="item.goodsId"
          @click="startSelect(item)"
          :class="
            (selectRes.goodsId === item.goodsId && isSelect) || item.isSelect
              ? 'active'
              : null
          "
        >
          <div class="left">
            <img :src="item.listPic" alt="" />
          </div>
          <div class="right">
            <div class="top">
              <div class="name">{{ item.name }}</div>
              <div class="subTitle">
                {{ item.content }}
              </div>
            </div>
            <div class="bottom">
              {{ item.desc }}
            </div>
          </div>
          <i></i>
        </div>
      </div>
      <div class="btnGroup">
        <div class="btnLeft" @click="reset">
          <div>
            <img src="../../assets/images/tianliangle/reset.png" alt="" /> 重置
          </div>
        </div>
        <div
          class="btnRight"
          :class="isActive ? 'active' : null"
          @click="submit"
        >
          <div>确认了,就这样</div>
        </div>
      </div>
    </div>
    <Overlay :show="showTip" @click="showTip = false">
      <div class="wrapper" @click.stop>
        <div class="content">
          <div class="title">
            <img src="../../assets/images/tianliangle/rule.png" alt="" />
          </div>
          <div class="main">
            <div class="h1Content">
              <div class="h1" style="margin-bottom: 5px">游戏共三轮</div>
              <div class="h1">第一轮：<span>座位排列</span></div>
              <div class="h1">第二轮：<span>年龄排序</span></div>
              <div class="h1">第三轮：<span>查看线索</span></div>
            </div>
            <div class="h2Content">
              <div class="h2">
                <span>1.</span>座位排列正确可获得一个锦囊并进入到 下一轮
              </div>
              <div class="h2">
                <span>2.</span>年龄排序正确可获得一个绿丝带藏品并 进入到下一轮
              </div>
              <div class="h2">
                <span>3.</span>查看所有线索后可获得徽章藏品，前300
                人额外获得实体剧本
              </div>
            </div>
            <div class="info">
              *每轮只有一次游戏机会，观看一次视频 增加一次游戏机会
            </div>
          </div>
          <div class="bottom" @click="showTip = false">
            我准备好了，立即开始
          </div>
        </div>
      </div>
    </Overlay>

    <Overlay :show="showTip2" @click="showTip2 = false">
      <div class="wrapper2">
        <div class="content">
          <div class="title">【排列正确】</div>
          <div class="middle">
            <img src="../../assets/images/tianliangle/lucky1.jpeg" alt="" />
          </div>
          <div class="info">恭喜您获得“锦囊” “锦囊”可在下一轮游戏中使用</div>
        </div>
        <div class="bottom">
          <div class="next" @click="goNext">进入下一轮</div>
          <div class="back" @click="goBack">返回</div>
        </div>
      </div>
    </Overlay>
    <Overlay :show="showTip1" @click="showTip1 = false">
      <div class="wrapper2">
        <div class="content">
          <div class="title">【排列错误】</div>
          <div class="middle">
            <img src="../../assets/images/tianliangle/error.png" alt="" />
          </div>
          <div class="info">游戏结束 观看视频可重新开始游戏</div>
        </div>
        <div class="bottom">
          <div class="next" @click="lookVideo">重新开始游戏</div>
          <div class="back" @click="goBack">返回</div>
        </div>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { Overlay } from 'vant';
import { addBridgeForAndroidWebView } from 'sdbridge-android';
export default {
  components: {
    Overlay,
  },
  data() {
    return {
      showTip: false,
      showTip1: false,
      showTip2: false,
      isSelect: false,
      query: {
        left: {},
        right: {},
        top: {},
        bottom: {},
      },
      gameList: [],
      selectRes: {},
      token: null,
      bridge: null,
      isAndroid:
        navigator.userAgent.indexOf('Android') > -1 ||
        navigator.userAgent.indexOf('Adr') > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    };
  },
  computed: {
    isActive() {
      return (
        this.query.left.listPic &&
        this.query.right.listPic &&
        this.query.top.listPic &&
        this.query.bottom.listPic
      );
    },
  },
  mounted() {
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log('TypeScript正在尝试挂载');
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          'window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!',
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log('window.WebViewJavascriptBridge 挂载成功！！');
    }
    this.token = this.$route.query.token;
    this.init();
    this.bridge.registerHandler('AdVoideoDone', () => {
      this.showTip1 = false;
      this.reset();
    });
    const that = this;
    window.AdVoideoDone = () => {
      that.showTip1 = false;
      that.reset();
    };
  },
  methods: {
    tip() {
      if (this.selectRes.goodsId) return;
      this.$toast('请先选择下方的角色');
    },
    goBack() {
      if (this.$route.query.platform) {
        if (this.isiOS) {
          this.bridge.callHandler(
            'jumptoapp',
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response));
            },
          );
        } else {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }));
        }
      } else {
        this.$router.back();
      }
    },
    lookVideo() {
      if (this.$route.query.platform) {
        if (this.isiOS) {
          this.bridge.callHandler(
            'jumptoapp',
            { key: 15 },
            function (response) {
              console.log(JSON.stringify(response));
            },
          );
        } else {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: 15 }));
        }
      }
    },
    async init() {
      const res = await this.$api.runDawnGame({
        token: this.token,
        positionId: this.$route.query.id,
      });
      res.gameInfo.forEach((e) => {
        e.isSelect = false;
      });
      this.gameList = res.gameInfo;
      if (res.data.status == 1) this.showTip2 = true;
    },
    startSelect(item) {
      if (item.isSelect) return;
      if (item.goodsId === this.selectRes.goodsId) {
        this.isSelect = !this.isSelect;
      } else {
        this.isSelect = true;
      }
      this.selectRes = item;
    },
    stopSelect(val) {
      if (!this.isSelect) return;
      if (this.query[val].listPic) {
        return this.$toast('该座位已选择');
      }
      this.selectRes.isSelect = true;
      this.query[val] = this.selectRes;
      this.isSelect = false;
    },
    reset() {
      this.selectRes = {};
      this.isSelect = false;
      this.gameList.forEach((e) => {
        e.isSelect = false;
      });
      this.query = {
        left: {},
        right: {},
        top: {},
        bottom: {},
      };
    },
    async submit() {
      const query = this.query;
      const step = [
        query.top.goodsId,
        query.right.goodsId,
        query.bottom.goodsId,
        query.left.goodsId,
      ];
      const res = await this.$api.verifyGame({
        token: this.token,
        step: step.join(','),
        positionId: this.$route.query.id,
      });
      if (res.code === 0) {
        this.showTip2 = true;
      } else {
        this.showTip1 = true;
        return this.$toast(res.msg);
      }
    },
    async goNext() {
      const res = await this.$api.nextGame({
        token: this.token,
        positionId: this.$route.query.id,
      });
      if (res.code == 0) {
        this.$router.replace(
          `/age?id=${this.$route.query.id}&token=${this.$route.query.token}`,
        );
      } else {
        this.$toast(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tianliangle {
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  .tips {
    width: 20px;
  }
  .container {
    padding: 11px 17px;
    background: url(../../assets/images/tianliangle/bg.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex: 1;
    overflow: auto;
    padding-bottom: 65px;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-size: 15px;
        font-family: PingFang SC;
        color: #ffffff;
      }
      .right {
        display: flex;
        align-items: center;
        img {
          width: 20px;
        }
        span {
          margin-left: 5px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #b9b692;
        }
      }
    }
    .table {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.33);
      border: 1px solid #545454;
      border-radius: 5px;
      padding: 15px;
      margin-top: 15px;
      .top,
      .bottom,
      .right,
      .left {
        width: 50px;
        height: 50px;
        background: rgba(0, 0, 0, 0.49);
        border: 1px solid rgba(185, 182, 146, 0.09);
        border-radius: 2px;
        box-shadow: 0 0 4px rgb(185, 182, 146, 0.09);
        position: relative;
        i {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          &::after {
            width: 60%;
            height: 5px;
            display: block;
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: #b8b8b8;
            visibility: visible;
          }
          &::before {
            background: #b8b8b8;
            height: 60%;
            width: 5px;
            display: block;
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.active {
          border: 1px solid rgba(185, 182, 146, 0.8);
          border-radius: 2px;
          box-shadow: 0 0 4px rgb(185, 182, 146, 0.9);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .middle {
        display: flex;
        align-items: center;
        img.banner {
          width: 180px;
          height: 180px;
          margin: 12px;
        }
      }
    }
    .list {
      .listItem {
        display: flex;
        align-items: center;
        background: rgba(21, 21, 21, 0.5);
        margin: 15px 10px;
        padding-left: 13px;
        border-radius: 5px;
        position: relative;
        &.active {
          background: transparent;
          i {
            border-radius: 5px;
            background: rgba(125, 125, 125, 0.5);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          }
        }
        .left {
          height: 70px;
          width: 40px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
          }
        }
        .right {
          flex: 1;
          margin-left: 15px;
          min-width: 1px;
          .top {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            .name {
              background-image: url(../../assets/images/tianliangle/titleBg.png);
              background-repeat: no-repeat;
              background-size: 100%;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
              width: 100px;
              height: 30px;
              margin-left: -10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              padding: 0 5px;
              box-sizing: border-box;
              line-height: 30px;
            }
            .subTitle {
              flex: 1;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #999999;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              min-width: 1px;
            }
          }
          .bottom {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #efefef;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 20px;
      background: #070707;
      .btnLeft {
        flex: 1;
        div {
          background: #b9b692;
          border-radius: 5px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #5e5b3a;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
      }
      .btnRight {
        padding-left: 20px;
        flex: 2;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          border-radius: 5px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          background: #434343;
          color: #999999;
        }
        &.active {
          div {
            color: #e5e5e5;
            background: #8d000a;
          }
        }
      }
    }
  }
  .wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-size: cover;
    background-image: url(../../assets/images/tianliangle/tipsBg.jpg);
    background-repeat: no-repeat;
    padding: 17px 14px;
    display: flex;
    .content {
      flex: 1;
      background: rgba(255, 255, 255, 0.84);
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .title {
        width: 110px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .main {
        flex: 1;
        text-align: left;
        width: 100%;
        margin: 17px 0 35px;
        .h1 {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 23px;
          span {
            font-weight: normal;
          }
        }
        .h2 {
          span {
            font-weight: 500;
            color: #c00000;
            display: inline-block;
            margin-right: 5px;
          }
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 23px;
        }
        .info {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c00000;
          line-height: 20px;
        }
        .h1Content {
        }
        .h2Content {
          margin: 15px 0 20px;
        }
      }
      .bottom {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #e5e5e5;
        width: 100%;
        height: 40px;
        background: #8d000a;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
    }
  }
  .wrapper2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 15px;
    display: flex;
    background-image: url(../../assets/images/tianliangle/boxBg.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    .content {
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 15px;
      .title {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .middle {
        margin: 20px 0 20px;
        img {
          width: 140px;
        }
      }
      .info {
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 23px;
      }
    }
    .bottom {
      text-align: center;
      margin-top: 20px;
      .next {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        width: 150px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px;
      }
      .back {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>
